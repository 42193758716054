/* You can add global styles to this file, and also import other style files */

.ant-upload.ant-upload-drag
    position: relative
    width: 100%
    height: 100%
    text-align: center
    background: #fafafa
    border: 1px dashed #d9d9d9
    border-radius: 2px
    cursor: pointer
    transition: border-color 0.3s
    margin-bottom: 1em
